import { GET, QUERY } from "@/core/services/store/request.module";
import { ErrorEventBus } from "@/core/lib/message.lib";

export default {
  data() {
    return {
      transaction_type: null,
      transaction_contact_person_dialog: false,
      transaction_property_dialog: false,
      transaction_billing: 0,
      transaction_contractor: 0,
      transaction_contact_person: 0,
      transaction_property: 0,
    };
  },
  methods: {
    createContractor() {},
    createContactPerson() {},
    createContractorProperty() {},
    getContractors(search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "contractor-list",
              data: { search: search },
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getContractorPersons(contractor, search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (contractor <= 0) {
            resolve([]);
          } else {
            _this.$store
              .dispatch(QUERY, {
                url: "contact-person-list/" + contractor,
                data: { search: search },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getContractorProperties(contractor, search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (contractor <= 0) {
            resolve([]);
          } else {
            _this.$store
              .dispatch(QUERY, {
                url: "property-list/" + contractor,
                data: { search: search },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getContractor() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.masterDialogClose();
          _this.$store
            .dispatch(GET, { url: "contractor/" + _this.contractor })
            .then((response) => {
              let billingAddress = _this.getBillingAddress(response.data);
              _this.transaction_billing = billingAddress.id;
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              let result = _this.getBillingAddress(_this.contractorArr);
              if (_this.lodash.isEmpty(result) === false) {
                _this.uBillingAddress = result;
                _this.uBillingAddress.country = result.country_name;
              }
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDefaultPerson(contractor) {
      let defaultPerson = {};
      const _this = this;
      if (contractor && _this.lodash.isEmpty(contractor.persons) === false) {
        for (let i = 0; i < contractor.persons.length; i++) {
          if (contractor.persons[i].default) {
            defaultPerson = _this.lodash.cloneDeep(contractor.persons[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(defaultPerson);
    },
    getPersonById(contractor, id) {
      let defaultPerson = {};
      const _this = this;
      if (contractor && _this.lodash.isEmpty(contractor.persons) === false) {
        for (let i = contractor.persons.length - 1; i >= 0; i--) {
          if (contractor.persons[i].id == id) {
            defaultPerson = _this.lodash.cloneDeep(contractor.persons[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(defaultPerson);
    },
    getPropertyById(contractor, id) {
      let property = {};
      const _this = this;
      if (contractor && _this.lodash.isEmpty(contractor.address) === false) {
        for (let i = contractor.address.length - 1; i >= 0; i--) {
          if (contractor.address[i].id == id) {
            property = _this.lodash.cloneDeep(contractor.address[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(property);
    },
    getBillingAddress(contractor) {
      let billing = {};
      const _this = this;
      if (contractor && _this.lodash.isEmpty(contractor.address) === false) {
        for (let i = contractor.address.length - 1; i >= 0; i--) {
          if (contractor.address[i].type == 2) {
            billing = _this.lodash.cloneDeep(contractor.address[i]);
            break;
          }
        }
      }
      return _this.lodash.cloneDeep(billing);
    },
    getEmailDetails(contractor) {
      let emailArr = [];
      const _this = this;
      if (contractor && _this.lodash.isEmpty(contractor.details) === false) {
        for (let i = 0; i < contractor.details.length; i++) {
          if (contractor.details[i].type === 1) {
            emailArr.push(contractor.details[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(emailArr);
    },
    getPhoneDetails(contractor) {
      let phoneArr = [];
      const _this = this;
      if (contractor && _this.lodash.isEmpty(contractor.details) === false) {
        for (let i = 0; i < contractor.details.length; i++) {
          if (contractor.details[i].type === 2) {
            phoneArr.push(contractor.details[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(phoneArr);
    },
    resetAll() {
      this.transaction_contractor = 0;
      this.transaction_contact_person = 0;
      this.transaction_property = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.transaction_contact_person_dialog = false;
      this.transaction_property_dialog = false;
    },
    selectContractorPerson(person) {
      this.transaction_contact_person = this.lodash.toSafeInteger(person);
      this.transaction_contact_person_dialog = false;
      if (this.transaction_property <= 0) {
        this.transaction_property_dialog = true;
      }
      this.checkTransaction();
    },
    selectContractorProperty(property) {
      this.transaction_property = this.lodash.toSafeInteger(property);
      this.checkTransaction();
    },
    checkTransaction() {
      const _this = this;
      let contractor = _this.lodash.toSafeInteger(_this.transaction_contractor);
      let contact_person = _this.lodash.toSafeInteger(
        _this.transaction_contact_person
      );
      let property = _this.lodash.toSafeInteger(_this.transaction_property);

      let billing = _this.lodash.toSafeInteger(_this.transaction_billing);

      if (_this.transaction_type == "quotation") {
        if (!billing) {
          ErrorEventBus.$emit(
            "update:error",
            "Contractor doesn't have billing address. Please update then try again."
          );
          return false;
        } else {
          _this.$router.push(
            _this.getDefaultRoute(_this.transaction_type + ".create", {
              query: {
                contractor,
                contact_person,
                billing,
              },
            })
          );
          return true;
        }
      }

      if (contractor > 0 && contact_person > 0 && property > 0) {
        _this.$router.push(
          _this.getDefaultRoute(_this.transaction_type + ".create", {
            query: {
              contractor,
              contact_person,
              property,
            },
          })
        );
      }
    },
    createTransaction(contractor, param) {
      const _this = this;
      _this.transaction_contractor = _this.lodash.toSafeInteger(contractor.id);
      _this.transaction_type = _this.lodash.toString(param);

      if (_this.transaction_type === "property") {
        _this.$router.push(
          _this.getDefaultRoute("property.create", {
            query: { contractor: _this.transaction_contractor },
          })
        );
      } else {
        if (contractor.persons_count > 1) {
          _this.transaction_contact_person_dialog = true;
        } else if (contractor.property_count > 1) {
          _this.transaction_property_dialog = true;
        }

        if (contractor.persons_count == 1) {
          let firstPerson = _this.lodash.head(contractor.persons);
          _this.transaction_contact_person = firstPerson.id;
        }

        if (contractor.property_count == 1) {
          let firstProperty = _this.lodash.head(contractor.property);
          _this.transaction_property = firstProperty.id;
        }

        _this.checkTransaction();
      }
    },
  },
};
