<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialog"
      :max-width="dialogWidth"
      content-class="project-dialog"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom"
        >
          <h4 class="custom-header-blue-text font-size-30">Select a Project</h4>
          <v-spacer></v-spacer>
          <v-btn icon dark color="cyan" v-on:click="$emit('resetAll', true)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-4 px-0 font-size-16">
          <v-container>
            <v-container fluid class="pt-0">
              <h5 class="font-weight-600 pb-4 mx-2 mx-md-4">
                Which Project would you like to create this for?
              </h5>
              <table width="100%">
                <tr>
                  <td>
                    <v-text-field
                      ref="search-Project"
                      v-model.trim="project"
                      :loading="searchLoader"
                      :background-color="
                        !isFocused ? 'grey lighten-3' : undefined
                      "
                      :flat="!isFocused && !isSearching"
                      :placeholder="placeholder"
                      autocomplete="off"
                      class="mx-2 mx-md-4 rounded-lg"
                      dense
                      hide-details
                      solo
                      @blur="isFocused = false"
                      @focus="isFocused = true"
                      @keydown.esc="onEsc"
                      @keydown.enter="onEnter"
                      @keydown.tab="onTab"
                    >
                      <template #prepend-inner>
                        <v-icon
                          :color="!isFocused ? 'grey' : undefined"
                          class="ml-1 mr-2"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                    </v-text-field>
                  </td>
                  <!-- <template v-if="getPermission('project:create')">
                    <td width="50" class="custom-header-blue-text text-center">
                      OR
                    </td>
                    <td>
                      <v-btn
                        class="mx-2 mx-md-4 custom-bold-button white--text"
                        v-on:click="createProjectDialog = true"
                        color="cyan"
                      >
                        <v-icon dark left>mdi-plus</v-icon>Project
                      </v-btn>
                    </td>
                  </template> -->
                </tr>
              </table>

              <div class="pt-4">
                <perfect-scrollbar
                  :options="{ suppressScrollX: true }"
                  class="scroll"
                  style="position: static"
                >
                  <v-list class="mx-2 mx-md-4" subheader three-line>
                    <v-skeleton-loader
                      v-if="searchLoader"
                      class="custom-skeleton"
                      type="list-item-avatar-two-line"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <template v-if="projectList.length > 0">
                        <v-list-item
                          v-on:click="selectProject(data)"
                          v-for="(data, index) in projectList"
                          :key="index"
                          link
                        >
                          <v-list-item-content>
                            <div>
                              <Barcode :barcode="data.barcode"></Barcode>
                            </div>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >{{ data.name }}
                            </v-list-item-title>
                            <!-- <v-list-item-subtitle
                              class="font-weight-500 font-size-14"
                            >
                              {{ getDefaultPerson(data.default_person) }}
                            </v-list-item-subtitle> -->
                          </v-list-item-content>
                          <v-list-item-action class="margin-auto">
                            <v-list-item-action-text
                              class="font-weight-500 mb-2 font-size-13"
                              >Start Date: {{ formatDate(data.started_at) }}
                            </v-list-item-action-text>
                            <v-list-item-action-text
                              class="font-weight-500 mb-2 font-size-13"
                              >Deadline: {{ formatDate(data.deadline) }}
                            </v-list-item-action-text>
                            <v-list-item-action-text
                              class="font-weight-500 mb-2 font-size-13"
                              >Total Cost: {{ data.cost }}
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <template v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >No Project(s) Found
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </template>
                  </v-list>
                </perfect-scrollbar>
              </div>
            </v-container>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      origin="top center"
      v-model="expenseDialog"
      :max-width="dialogWidth"
      persistent
    >
      <v-card>
        <v-card-text class="py-4 px-0 font-size-16">
          <v-container>
            <v-row>
              <v-col
                md="6"
                class="text-center custom-border-right link"
                v-on:click="selectedPersonal()"
              >
                <v-avatar size="200">
                  <v-img
                    contain
                    :src="$assetURL('media/default/no-profile-image.png')"
                  ></v-img>
                </v-avatar>
                <h3
                  class="color-custom-blue font-weight-600 font-size-24 text-uppercase"
                >
                  Personal
                </h3>
              </v-col>
              <v-col
                md="6"
                class="text-center custom-border-left link"
                v-on:click="selectedProject()"
              >
                <v-btn
                  icon
                  dark
                  color="cyan"
                  class="personal-close-btn"
                  v-on:click="$emit('resetAll', true)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-avatar size="200">
                  <v-img
                    contain
                    :src="$assetURL('media/default/no-profile-image.png')"
                  ></v-img>
                </v-avatar>
                <h3
                  class="color-custom-blue font-weight-600 font-size-24 text-uppercase"
                >
                  Project
                </h3>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Dialog
      v-if="createProjectDialog"
      :commonDialog="createProjectDialog"
      :dialogWidth="1024"
    >
      <template v-slot:title>Create Project</template>
      <template v-slot:body>
        <v-form
          ref="createProjectOnly"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="createProjectOnly"
        >
          <DialogContactDetails
            :isDialog="true"
            v-on:saveContactDetail="updateProjectDetailOnly"
          >
          </DialogContactDetails>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="createProjectOnly"
          >Save Project
        </v-btn>
        <v-btn
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="createProjectDialog = false"
          >Close
        </v-btn>
      </template>
    </Dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import { QUERY } from "@/core/services/store/request.module";
import DialogContactDetails from "@/view/pages/contractor/create-or-update/Contact-Details.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      formValid: true,
      formLoading: false,
      isFocused: false,
      searchLoader: true,
      expenseDialog: false,
      project: null,
      timeoutLimit: 500,
      timeout: null,
      projectArr: new Object(),
      createProjectDialog: false,
      projectList: new Array(),
      dialog: false,
    };
  },
  props: {
    isExpense: {
      type: Boolean,
      default: false,
    },
    projectDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: Number,
      default: 768,
    },
  },
  components: {
    Dialog,
    DialogContactDetails,
    Barcode,
  },
  watch: {
    projectDialog() {
      this.init();
    },
    project(param) {
      if (!param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.searchProject();
        }, _this.timeoutLimit);
      }
    },
  },
  methods: {
    getProjects(search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "project-list",
              data: { search: search },
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    selectedProject() {
      this.expenseDialog = false;
      this.dialog = true;
    },
    init() {
      if (!this.isExpense) {
        this.dialog = this.projectDialog;
      } else {
        this.expenseDialog = true;
      }
    },
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      if (this.isSearching) {
        this.searchProject();
      }
    },
    onTab() {
      if (this.isSearching) {
        this.searchProject();
      }
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.project = null;
        _this.isFocused = false;
      });
    },
    searchProject() {
      const _this = this;
      _this.searchLoader = true;
      _this
        .getProjects(_this.project)
        .then((response) => {
          _this.projectList = response;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.searchLoader = false;
          _this.createProjectDialog = false;
        });
    },
    selectProject(param) {
      this.$emit("selectProject", param.id);
    },
    updateProjectDetailOnly(param) {
      this.projectArr = param;
    },
  },
  mounted() {
    this.init();
    this.searchProject();
    if (this.$refs["search-project"]) {
      this.$refs["search-project"].focus();
    }
  },
  computed: {
    isSearching() {
      return this.project && this.project.length > 0;
    },
    placeholder() {
      return this.isFocused
        ? "Start Typing then press enter"
        : "Search Project...";
    },
    getDefaultImage() {
      return this.$filePath + "storage/uploads/images/no-image.png";
    },
  },
};
</script>
